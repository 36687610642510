import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

export const SupportPageTemplate = ({
  content,
  contentComponent,
  image,
  title,
  helmet
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <PreviewCompatibleImage
              imageInfo={{
                image: image,
                alt: `Image for Prisoner Support Page`,
              }}
            />
            <PostContent content={content} />
          </div>
        </div>
      </div>
    </section>
  )
}

SupportPageTemplate.propTypes = {
  content: PropTypes.string.isRequired,
  image: PropTypes.object,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const SupportPage = ({ data }) => {
  const { markdownRemark: page } = data
  return (
    <Layout activePage="support">
      <SupportPageTemplate
        content={page.frontmatter.blurb}
        contentComponent={Content}
        image={page.frontmatter.image}
        helmet={
          <Helmet titleTemplate="%s | Prisoner Support">
            <title>{`SM28 | Prisoner Support`}</title>
            {/* TODO SEO DESCRIPTION
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            /> */}
          </Helmet>
        }
        title='Prisoner Support Project'
      />
    </Layout>
  )
}

SupportPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
}

export default SupportPage

export const pageQuery = graphql`
  query SupportPage{
    markdownRemark(frontmatter: { templateKey: { eq: "support" } }) {
      frontmatter {
        blurb
        image{
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
